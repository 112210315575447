import React, { useEffect, useState } from "react";
import { useNavigate, useLocation } from "react-router-dom";
import { Box, Card, CardContent, Typography, Grid, Button, Avatar, CircularProgress, Chip, Divider, Paper } from "@mui/material";
import { Person } from "@mui/icons-material";
import { DateTime } from "luxon";
import { getInviteDetails } from "../api/invitation";

const MeetingConfirmationPage = () => {
	const navigate = useNavigate();
	const location = useLocation();

	const [loading, setLoading] = useState(true);
	const [error, setError] = useState(null);
	const [invitation, setInvitation] = useState(null);

	const queryParams = new URLSearchParams(location.search);
	const invitationId = queryParams.get("id");

	useEffect(() => {
		if (!invitationId) {
			navigate("/");
			return;
		}

		const fetchInvitation = async () => {
			try {
				const invite = await getInviteDetails(invitationId);
				setInvitation(invite);
			} catch (err) {
				console.error("Error fetching invitation:", err);
				setError("Could not retrieve meeting details.");
			} finally {
				setLoading(false);
			}
		};

		fetchInvitation();
	}, [invitationId, navigate]);

	if (loading) {
		return (
			<Box sx={{ display: "flex", justifyContent: "center", mt: 10 }}>
				<CircularProgress />
			</Box>
		);
	}

	if (error || !invitation) {
		return (
			<Box sx={{ textAlign: "center", mt: 10 }}>
				<Typography color="error" variant="h6">
					{error || "Meeting not found."}
				</Typography>
				<Button variant="contained" onClick={() => navigate("/")}>
					Go to Home
				</Button>
			</Box>
		);
	}

	const { inviter, inviteeEmail, status, selectedSlot, suggestedSlots } = invitation;

	const formatTimestamp = ts => DateTime.fromSeconds(ts).toLocaleString(DateTime.DATETIME_MED);

	return (
		<Box sx={{ backgroundColor: "#f5f7fa", minHeight: "100vh", py: 6, px: 2 }}>
			<Card sx={{ maxWidth: 700, mx: "auto", p: 4, borderRadius: 3, boxShadow: 3 }}>
				<CardContent>
					<Typography variant="h4" gutterBottom sx={{ fontWeight: 600, textAlign: "center", mb: 3 }}>
						🎉 Meeting Confirmed!
					</Typography>
					<Typography variant="body1" color="text.secondary" textAlign="center" sx={{ mb: 4 }}>
						Your meeting has been successfully scheduled. Below are the details:
					</Typography>

					<Paper elevation={0} sx={{ p: 2, mb: 3, backgroundColor: "#f9fafb", borderRadius: 2 }}>
						<Grid container spacing={2} alignItems="center">
							<Grid item>
								<Avatar sx={{ bgcolor: "#1e40af" }}>
									<Person />
								</Avatar>
							</Grid>
							<Grid item xs>
								<Typography variant="subtitle1">{inviter?.name || "Host"}</Typography>
								<Typography variant="body2" color="text.secondary">
									{inviter?.email || "N/A"}
								</Typography>
							</Grid>
						</Grid>
					</Paper>

					<Box sx={{ mb: 2 }}>
						<Typography variant="subtitle2" sx={{ fontWeight: 600 }}>
							📩 Invitee Email
						</Typography>
						<Typography variant="body1">
							{inviteeEmail}
							<Chip label={status.toUpperCase()} color={status === "accepted" ? "success" : "default"} size="small" sx={{ ml: 2 }} />
						</Typography>
					</Box>

					<Divider sx={{ my: 3 }} />

					{selectedSlot && (
						<Box sx={{ mb: 3 }}>
							<Typography variant="subtitle2" sx={{ fontWeight: 600 }}>
								🕒 Scheduled Slot
							</Typography>
							<Typography variant="body2" color="text.secondary">
								{selectedSlot.formatted || `${formatTimestamp(selectedSlot.start)} - ${formatTimestamp(selectedSlot.end)}`}
							</Typography>
						</Box>
					)}

					{suggestedSlots?.length > 0 && (
						<Box>
							<Typography variant="subtitle2" sx={{ fontWeight: 600 }}>
								📅 Other Suggested Slots
							</Typography>
							{suggestedSlots.map(slot => (
								<Typography
									key={slot._id}
									variant="body2"
									sx={{
										color: "#475569",
										fontSize: "0.9rem",
										mt: 0.5,
										borderLeft: "3px solid #cbd5e1",
										pl: 1,
									}}>
									{slot.formatted || `${formatTimestamp(slot.start)} - ${formatTimestamp(slot.end)}`}
								</Typography>
							))}
						</Box>
					)}

					{/* <Box sx={{ display: "flex", justifyContent: "center", mt: 5 }}>
						<Button variant="contained" color="primary" size="large" sx={{ px: 4, borderRadius: "999px", textTransform: "none" }}>
							Add to Calendar
						</Button>
					</Box> */}
				</CardContent>
			</Card>
		</Box>
	);
};

export default MeetingConfirmationPage;
