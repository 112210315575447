import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { DateTime } from "luxon";
import { fetchUser, logout } from "../redux/authSlice";
import { fetchEvents, selectEvents } from "../redux/eventsSlice";
import { useNavigate } from "react-router-dom";
import AlertConfiguration from "./AlertConfiguration";
import InviteModal from "./InviteModal";

// Assuming you'll create these components
import ConnectCalendarButton from "./ConnectCalendarButton";
import StatCard from "./StatCard";
import EventCard from "./EventCard";
import { getLoginUrl } from "../api/user";
import WorkingHoursPopup from "./WorkingHoursPopup";

const Dashboard = () => {
	const dispatch = useDispatch();
	const navigate = useNavigate();
	const { user, token } = useSelector(state => state.auth);
	const { events, loading, error } = useSelector(selectEvents);

	// Redirect to Microsoft OAuth
	const connectCalendar = async provider => {
		const { url } = await getLoginUrl(token, provider);
		window.location.href = url;
	};

	useEffect(() => {
		if (!token) {
			navigate("/login");
			return;
		}
		dispatch(fetchUser(token));
		dispatch(fetchEvents(token));
	}, [token, dispatch, navigate]);

	const handleLogout = () => {
		dispatch(logout());
		navigate("/login");
	};

	const getUpcomingMeetingsCount = () => {
		return events.filter(event => DateTime.fromSeconds(event.start?.timestamp || 0) > DateTime.now()).length;
	};

	const getTodayMeetingsCount = () => {
		const today = DateTime.now().startOf("day");
		return events.filter(event => {
			const eventDate = DateTime.fromSeconds(event.start?.timestamp || 0);
			return eventDate >= today && eventDate < today.plus({ days: 1 });
		}).length;
	};

	return (
		<div style={styles.pageContainer}>
			{/* Top Navigation Bar */}
			<div style={styles.topNav}>
				<div style={styles.brand}>
					<h1 style={styles.brandText}>Hyacinthe</h1>
					<div >
						<InviteModal />
					</div>
				</div>
				<div style={styles.userNav}>
					{user?.picture && <img src={user.picture} alt="Profile" style={styles.smallProfilePic} />}
					<span style={styles.userName}>{user?.name || "User"}</span>
					<button onClick={handleLogout} style={styles.logoutButton}>
						Logout
					</button>
				</div>
			</div>

			{/* Main Content */}
			<div style={styles.mainContent}>
				{/* Left Sidebar */}
				<div style={styles.sidebar}>
					<div style={styles.profileSection}>
						{user?.picture && <img src={user.picture} alt="Profile" style={styles.profilePicture} />}
						<h2 style={styles.profileName}>{user?.name || "User"}</h2>
						<p style={styles.profileEmail}>{user?.email || "N/A"}</p>
					</div>

					<div style={styles.calendarConnections}>
						<h3 style={styles.sectionTitle}>Calendar Connections</h3>
						<ConnectCalendarButton
							type="google"
							isConnected={!!user?.googleId}
							calendarAccessGranted={!!user?.googleCalendarAccessGranted}
							onClick={() => connectCalendar("google")}
						/>
						<ConnectCalendarButton
							type="microsoft"
							isConnected={!!user?.microsoftId}
							calendarAccessGranted={!!user?.microsoftCalendarAccessGranted}
							onClick={() => connectCalendar("microsoft")}
						/>
					</div>

					<div style={styles.profileSection}>
						<h3 style={styles.sectionTitle}>Working Hours</h3>
						<WorkingHoursPopup />
					</div>

					<AlertConfiguration user={user} />
				</div>

				{/* Main Dashboard Area */}
				<div style={styles.dashboardMain}>
					{/* Stats Cards */}
					<div style={styles.statsGrid}>
						<StatCard title="Today's Meetings" value={getTodayMeetingsCount()} icon="📅" />
						<StatCard title="Upcoming Meetings" value={getUpcomingMeetingsCount()} icon="🗓️" />
						<StatCard title="Connected Calendars" value={!!user?.microsoftId + !!user?.googleId} icon="🔗" />
					</div>
					{/* Events Section */}
					<div style={styles.eventsSection}>
						<div style={styles.eventsSectionHeader}>
							<h2 style={styles.sectionTitle}>Your Meetings</h2>
						</div>

						{loading ? (
							<div style={styles.loadingState}>
								<div style={styles.spinner}></div>
								<p>Loading your meetings...</p>
							</div>
						) : error ? (
							<div style={styles.errorState}>
								<p>Failed to load meetings: {error}</p>
								<button style={styles.retryButton} onClick={() => dispatch(fetchEvents(token))}>
									Retry
								</button>
							</div>
						) : events.length === 0 ? (
							<div style={styles.emptyState}>
								<p>No upcoming meetings found.</p>
								<p style={styles.emptyStateSubtext}>Connect your calendars to see your meetings here.</p>
							</div>
						) : (
							<div style={styles.eventsGrid}>
								{events
									.filter(event => DateTime.fromSeconds(event.start?.timestamp || 0) > DateTime.now())
									.sort((a, b) => (a.start?.timestamp || 0) - (b.start?.timestamp || 0))
									.map(event => (
										<EventCard key={event.id} event={event} style={styles.eventCard} />
									))}
							</div>
						)}
					</div>
				</div>
			</div>


		</div>
	);
};

const styles = {
	pageContainer: {
		minHeight: "100vh",
		backgroundColor: "#f8fafc",
		// fontFamily: "'Inter', sans-serif",
	},
	topNav: {
		display: "flex",
		justifyContent: "space-between",
		alignItems: "center",
		padding: "1rem 2rem",
		backgroundColor: "white",
		boxShadow: "0 1px 3px rgba(0,0,0,0.1)",
		position: "sticky",
		top: 0,
		zIndex: 100,
	},
	brand: {
		display: "flex",
		alignItems: "center",
		gap: "16px",
	},
	brandText: {
		fontSize: "1.5rem",
		fontWeight: "bold",
		color: "#1a365d",
		margin: 0,
	},
	userNav: {
		display: "flex",
		alignItems: "center",
		gap: "1rem",
	},
	smallProfilePic: {
		width: "32px",
		height: "32px",
		borderRadius: "50%",
		objectFit: "cover",
	},
	userName: {
		fontWeight: 500,
		color: "#2d3748",
	},
	mainContent: {
		display: "flex",
		gap: "2rem",
		padding: "2rem",
		maxWidth: "1600px",
		margin: "0 auto",
	},
	sidebar: {
		width: "300px",
		flexShrink: 0,
	},
	profileSection: {
		backgroundColor: "white",
		borderRadius: "12px",
		padding: "1.5rem",
		textAlign: "center",
		boxShadow: "0 1px 3px rgba(0,0,0,0.1)",
		marginBottom: "1.5rem",
	},
	profilePicture: {
		width: "120px",
		height: "120px",
		borderRadius: "60px",
		objectFit: "cover",
		margin: "0 auto 1rem",
		border: "4px solid #e2e8f0",
	},
	profileName: {
		fontSize: "1.25rem",
		fontWeight: "bold",
		color: "#2d3748",
		margin: "0 0 0.5rem",
	},
	profileEmail: {
		color: "#718096",
		fontSize: "0.875rem",
	},
	dashboardMain: {
		flex: 1,
	},
	statsGrid: {
		display: "grid",
		gridTemplateColumns: "repeat(auto-fit, minmax(240px, 1fr))",
		gap: "1.5rem",
		marginBottom: "2rem",
	},
	eventsSection: {
		backgroundColor: "white",
		borderRadius: "12px",
		padding: "1.5rem",
		boxShadow: "0 1px 3px rgba(0,0,0,0.1)",
	},
	eventsSectionHeader: {
		display: "flex",
		justifyContent: "space-between",
		alignItems: "center",
		marginBottom: "1.5rem",
	},
	sectionTitle: {
		fontSize: "1.25rem",
		fontWeight: "bold",
		color: "#2d3748",
		margin: 0,
	},
	viewToggle: {
		display: "flex",
		gap: "0.5rem",
		backgroundColor: "#f1f5f9",
		padding: "0.25rem",
		borderRadius: "8px",
	},
	viewToggleButton: {
		padding: "0.5rem 1rem",
		border: "none",
		borderRadius: "6px",
		backgroundColor: "transparent",
		cursor: "pointer",
		fontSize: "0.875rem",
		color: "#64748b",
		transition: "all 0.2s",
	},
	viewToggleButtonActive: {
		backgroundColor: "white",
		color: "#1a365d",
		boxShadow: "0 1px 3px rgba(0,0,0,0.1)",
	},
	eventsGrid: {
		display: "grid",
		gridTemplateColumns: "repeat(auto-fill, minmax(300px, 1fr))",
		gap: "1rem",
	},
	calendarConnections: {
		backgroundColor: "white",
		borderRadius: "12px",
		padding: "1.5rem",
		boxShadow: "0 1px 3px rgba(0,0,0,0.1)",
		marginBottom: "1.5rem",
	},
	logoutButton: {
		padding: "0.5rem 1rem",
		backgroundColor: "#fee2e2",
		color: "#dc2626",
		border: "none",
		borderRadius: "6px",
		cursor: "pointer",
		fontWeight: "500",
		transition: "all 0.2s",
		"&:hover": {
			backgroundColor: "#fecaca",
		},
	},
	loadingState: {
		textAlign: "center",
		padding: "3rem",
		color: "#64748b",
	},
	spinner: {
		width: "40px",
		height: "40px",
		margin: "0 auto 1rem",
		border: "4px solid #e2e8f0",
		borderTopColor: "#3b82f6",
		borderRadius: "50%",
		animation: "spin 1s linear infinite",
	},
	errorState: {
		textAlign: "center",
		padding: "3rem",
		color: "#dc2626",
	},
	retryButton: {
		marginTop: "1rem",
		padding: "0.5rem 1rem",
		backgroundColor: "#fee2e2",
		color: "#dc2626",
		border: "none",
		borderRadius: "6px",
		cursor: "pointer",
		fontWeight: "500",
	},
	emptyState: {
		textAlign: "center",
		padding: "3rem",
		color: "#64748b",
	},
	emptyStateSubtext: {
		fontSize: "0.875rem",
		color: "#94a3b8",
		marginTop: "0.5rem",
	},
};

export default Dashboard;
