import React, { useState } from "react";
import {
	Dialog,
	DialogTitle,
	DialogContent,
	DialogActions,
	Button,
	Checkbox,
	TextField,
	IconButton,
	CircularProgress,
	Autocomplete,
} from "@mui/material";
import { Add, Remove } from "@mui/icons-material";
import moment from "moment-timezone";
import { useSelector } from "react-redux";
import { getWorkSchedule, saveWorkSchedule } from "../api/workschedule";

const WorkingHoursPopup = () => {
	const token = useSelector(state => state.auth.token);

	const [open, setOpen] = useState(false);
	const [timezone, setTimezone] = useState(moment.tz.guess());
	const [loading, setLoading] = useState(false);
	const [saving, setSaving] = useState(false);
	const [workingHours, setWorkingHours] = useState({});

	const allTimezones = moment.tz.names();

	const defaultSchedule = {
		Monday: { available: true, slots: [{ from: "09:00", to: "17:00" }] },
		Tuesday: { available: true, slots: [{ from: "09:00", to: "17:00" }] },
		Wednesday: { available: true, slots: [{ from: "09:00", to: "17:00" }] },
		Thursday: { available: true, slots: [{ from: "09:00", to: "17:00" }] },
		Friday: { available: true, slots: [{ from: "09:00", to: "17:00" }] },
		Saturday: { available: false, slots: [] },
		Sunday: { available: false, slots: [] },
	};

	const handleOpen = async () => {
		setOpen(true);
		setLoading(true);
		try {
			const schedule = await getWorkSchedule(token, timezone);
			setTimezone(schedule.timezone);

			const convertedSchedule = Object.keys(schedule.schedule).reduce((result, day) => {
				const { available, slots } = schedule.schedule[day];
				result[day] = {
					available,
					slots: slots.map(slot => ({
						from: slot.from,
						to: slot.to,
					})),
				};
				return result;
			}, {});
			setWorkingHours(convertedSchedule);
		} catch (error) {
			console.error("Error fetching work schedule:", error);
			alert("Failed to load working hours. Using default schedule.");
			setWorkingHours(defaultSchedule);
		} finally {
			setLoading(false);
		}
	};

	const handleClose = () => setOpen(false);

	const handleAvailabilityChange = day => {
		setWorkingHours({
			...workingHours,
			[day]: {
				...workingHours[day],
				available: !workingHours[day].available,
				slots: !workingHours[day].available ? [{ from: "09:00", to: "17:00" }] : [],
			},
		});
	};

	const handleSlotChange = (day, index, field, value) => {
		const updatedSlots = [...workingHours[day].slots];
		updatedSlots[index][field] = value;
		setWorkingHours({
			...workingHours,
			[day]: { ...workingHours[day], slots: updatedSlots },
		});
	};

	const handleAddSlot = day => {
		const updatedSlots = [...workingHours[day].slots, { from: "", to: "" }];
		setWorkingHours({
			...workingHours,
			[day]: { ...workingHours[day], slots: updatedSlots },
		});
	};

	const handleRemoveSlot = (day, index) => {
		const updatedSlots = [...workingHours[day].slots];
		updatedSlots.splice(index, 1);
		setWorkingHours({
			...workingHours,
			[day]: { ...workingHours[day], slots: updatedSlots },
		});
	};

	const handleSave = async () => {
		setSaving(true);

		const convertedSchedule = Object.keys(workingHours).reduce((result, day) => {
			const { available, slots } = workingHours[day];
			const convertedSlots = slots.map(slot => ({
				from: slot.from,
				to: slot.to,
			}));
			result[day] = {
				available,
				slots: available ? convertedSlots : [],
			};
			return result;
		}, {});

		const payload = {
			timezone,
			...convertedSchedule,
		};

		try {
			await saveWorkSchedule(token, payload);
			alert("Working hours saved successfully!");
			handleClose();
		} catch (error) {
			console.error("Error saving working hours:", error);
			alert("Failed to save working hours.");
		} finally {
			setSaving(false);
		}
	};

	return (
		<div style={{ marginTop: "10px" }}>
			<Button variant="contained" color="primary" onClick={handleOpen}>
				Set working hours
			</Button>

			<Dialog open={open} onClose={handleClose} maxWidth="md" fullWidth>
				<DialogTitle>Configure Working Hours</DialogTitle>
				<DialogContent>
					{loading ? (
						<div style={{ textAlign: "center", padding: "20px" }}>
							<CircularProgress />
						</div>
					) : (
						<div style={{ marginTop: "10px" }}>
							<div style={{ width: "250px", marginBottom: "15px" }}>
								<Autocomplete
									options={allTimezones}
									value={timezone}
									onChange={(e, newValue) => newValue && setTimezone(newValue)}
									renderInput={params => <TextField {...params} label="Timezone" variant="outlined" />}
								/>
							</div>

							{Object.keys(workingHours).map(day => (
								<div key={day} style={{ marginBottom: "15px" }}>
									<Checkbox checked={workingHours[day].available} onChange={() => handleAvailabilityChange(day)} />
									<span>{day}</span>
									{workingHours[day].available &&
										workingHours[day].slots.map((slot, index) => (
											<div key={index} style={{ display: "flex", alignItems: "center", gap: "10px" }}>
												<TextField
													type="time"
													value={slot.from}
													onChange={e => handleSlotChange(day, index, "from", e.target.value)}
													inputProps={{ style: { fontSize: "12px" } }}
												/>
												<span>to</span>
												<TextField
													type="time"
													value={slot.to}
													onChange={e => handleSlotChange(day, index, "to", e.target.value)}
													inputProps={{ style: { fontSize: "12px" } }}
												/>
												{workingHours[day].slots.length > 1 && (
													<IconButton onClick={() => handleRemoveSlot(day, index)} size="small">
														<Remove />
													</IconButton>
												)}
											</div>
										))}
									{workingHours[day].available && (
										<Button onClick={() => handleAddSlot(day)} startIcon={<Add />} size="small">
											Add Slot
										</Button>
									)}
								</div>
							))}
						</div>
					)}
				</DialogContent>
				<DialogActions>
					<Button onClick={handleClose} variant="outlined" color="secondary" disabled={saving}>
						Close
					</Button>
					<Button onClick={handleSave} variant="contained" color="primary" disabled={saving}>
						{saving ? "Saving..." : "Save"}
					</Button>
				</DialogActions>
			</Dialog>
		</div>
	);
};

export default WorkingHoursPopup;
