import React, { useState } from "react";
import { Formik, Form, Field } from "formik";
import * as Yup from "yup";
import { sendInvitations } from "../api/invitation";
import { useSelector } from "react-redux";
import { toast } from "react-toastify";

const validationSchema = Yup.object().shape({
	emails: Yup.string().test("emails", "Invalid email format", function (value) {
		if (!value) return true; // Allow empty value initially
		const emails = value.split(",").map(email => email.trim());
		return emails.every(email => email === "" || /^[^\s@]+@[^\s@]+\.[^\s@]+$/.test(email));
	}),
});

const EmailTagInput = ({ field, form }) => {
	const [inputValue, setInputValue] = useState("");
	const emails = field.value ? field.value.split(",").filter(Boolean) : [];

	const addEmail = email => {
		const trimmedEmail = email.trim();
		if (trimmedEmail && /^[^\s@]+@[^\s@]+\.[^\s@]+$/.test(trimmedEmail)) {
			const newEmails = [...emails, trimmedEmail].join(",");
			form.setFieldValue(field.name, newEmails);
			setInputValue("");
			return true;
		}
		return false;
	};

	const handleInputChange = e => {
		const value = e.target.value;
		if (value.endsWith(",")) {
			const email = value.slice(0, -1).trim();
			addEmail(email);
		} else {
			setInputValue(value);
		}
	};

	const handleKeyDown = e => {
		if (e.key === "Enter") {
			e.preventDefault();
			addEmail(inputValue);
		} else if (e.key === "Backspace" && !inputValue && emails.length > 0) {
			const newEmails = emails.slice(0, -1).join(",");
			form.setFieldValue(field.name, newEmails);
		}
	};

	const handleBlur = e => {
		if (inputValue) {
			addEmail(inputValue);
		}
	};

	const removeEmail = indexToRemove => {
		const newEmails = emails.filter((_, index) => index !== indexToRemove).join(",");
		form.setFieldValue(field.name, newEmails);
	};

	return (
		<div style={styles.tagInputContainer}>
			<div style={styles.tagList}>
				{emails.map((email, index) => (
					<span key={index} style={styles.tag}>
						{email}
						<button type="button" onClick={() => removeEmail(index)} style={styles.tagRemoveButton}>
							×
						</button>
					</span>
				))}
				<input
					type="text"
					value={inputValue}
					onChange={handleInputChange}
					onKeyDown={handleKeyDown}
					onBlur={handleBlur}
					placeholder={emails.length === 0 ? "Email" : ""}
					style={styles.tagInput}
				/>
			</div>
			{form.touched[field.name] && form.errors[field.name] && <div style={styles.errorMessage}>{form.errors[field.name]}</div>}
		</div>
	);
};

const InviteModal = () => {
	const [isOpen, setIsOpen] = useState(false);
	const [sendingInvites, setSendingInvites] = useState(false);
	const token = useSelector(state => state.auth.token);

	const handleSubmit = async (values, { resetForm }) => {
		const emailList = values.emails.split(",").filter(Boolean);

		if (emailList.length === 0) return;

		console.log("Sending invites to:", emailList);
		setSendingInvites(true);
		try{
			await sendInvitations(token, emailList);
			toast.success("Invitation sent successfully");
		}catch(error){
			toast.error(error.message || 'Failed to send invitations');
		} finally {
			setSendingInvites(false);
			resetForm();
			setIsOpen(false);
		}
	};

	if (!isOpen) {
		return (
			<button onClick={() => setIsOpen(true)} style={styles.triggerButton}>
				Invite to Schedule
			</button>
		);
	}

	return (
		<div style={styles.modalOverlay}>
			<div style={styles.modalContent}>
				<button onClick={() => setIsOpen(false)} style={styles.closeButton}>
					✕
				</button>

				<div style={styles.modalHeader}>
					<h2 style={styles.title}>Invite to Schedule</h2>
					<p style={styles.subtitle}>Enter the email of the person you want to meet with; we will send them the best options to schedule you.</p>
				</div>

				<Formik initialValues={{ emails: "" }} validationSchema={validationSchema} onSubmit={handleSubmit}>
					<Form style={styles.form}>
						<Field name="emails" component={EmailTagInput} />
						<button type="submit" style={styles.submitButton}>
							{sendingInvites ? "Sending..." : "Send"}
							<svg
								width="20"
								height="20"
								viewBox="0 0 24 24"
								fill="none"
								stroke="currentColor"
								strokeWidth="2"
								strokeLinecap="round"
								strokeLinejoin="round"
								style={{ marginLeft: "8px" }}>
								<path d="M5 12h14M12 5l7 7-7 7" />
							</svg>
						</button>
					</Form>
				</Formik>
			</div>
		</div>
	);
};

const styles = {
	modalOverlay: {
		position: "fixed",
		top: 0,
		left: 0,
		right: 0,
		bottom: 0,
		backgroundColor: "rgba(0, 0, 0, 0.5)",
		display: "flex",
		alignItems: "center",
		justifyContent: "center",
		zIndex: 1000,
	},
	modalContent: {
		backgroundColor: "white",
		borderRadius: "24px",
		padding: "32px",
		width: "100%",
		maxWidth: "480px",
		margin: "0 16px",
		position: "relative",
	},
	closeButton: {
		position: "absolute",
		top: "16px",
		right: "16px",
		border: "none",
		background: "none",
		fontSize: "20px",
		color: "#666",
		cursor: "pointer",
	},
	modalHeader: {
		textAlign: "center",
		marginBottom: "24px",
	},
	title: {
		fontSize: "32px",
		color: "#4361ee",
		marginBottom: "16px",
		margin: 0,
	},
	subtitle: {
		fontSize: "16px",
		color: "#666",
		lineHeight: "1.5",
		margin: "16px 0",
	},
	form: {
		display: "flex",
		flexDirection: "column",
		gap: "16px",
	},
	tagInputContainer: {
		width: "100%",
	},
	tagList: {
		display: "flex",
		flexWrap: "wrap",
		gap: "8px",
		padding: "8px",
		border: "1px solid #ddd",
		borderRadius: "8px",
		minHeight: "45px",
	},
	tag: {
		backgroundColor: "#e8eeff",
		color: "#4361ee",
		padding: "4px 8px",
		borderRadius: "4px",
		display: "flex",
		alignItems: "center",
		fontSize: "14px",
	},
	tagRemoveButton: {
		background: "none",
		border: "none",
		color: "#4361ee",
		marginLeft: "4px",
		cursor: "pointer",
		fontSize: "16px",
		padding: "0 2px",
	},
	tagInput: {
		border: "none",
		outline: "none",
		flex: 1,
		fontSize: "16px",
		minWidth: "120px",
		padding: "4px",
	},
	submitButton: {
		width: "100%",
		padding: "12px 24px",
		backgroundColor: "#4361ee",
		color: "white",
		border: "none",
		borderRadius: "24px",
		fontSize: "16px",
		cursor: "pointer",
		display: "flex",
		alignItems: "center",
		justifyContent: "center",
	},
	triggerButton: {
		padding: "8px 16px",
		backgroundColor: "#4361ee",
		color: "white",
		border: "none",
		borderRadius: "8px",
		fontSize: "14px",
		fontWeight: "500",
		cursor: "pointer",
	},
	errorMessage: {
		color: "#dc2626",
		fontSize: "14px",
		marginTop: "8px",
	},
};

export default InviteModal;
