import { API } from ".";


export const sendInvitations = async (token, emails) => {
    try {
        const response = await API.post('/invitation', { emails }, {
            headers: { Authorization: `Bearer ${token}` },
        });
        return response.data;
    } catch (error) {
        throw error.response?.data || "Failed to send invitations";
    }

};

export const getInviteDetails = async (inviteId) => {
    try {
        const response = await API.get(`/invitation/${inviteId}/`);
        return response.data;
    } catch (error) {
        throw error.response?.data || "Failed to fetch invitations";
    }

};