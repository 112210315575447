import { API } from ".";


export const saveWorkSchedule = async (token, payload) => {
    try {
        const response = await API.post('/work-schedule', payload, {
            headers: { Authorization: `Bearer ${token}` },
        });
        return response.data;
    } catch (error) {
        throw error.response?.data || "Failed to send invitations";
    }

};

export const getWorkSchedule = async (token, currentTimezone) => {
    const response = await API.get(`/work-schedule?current_timezone=${currentTimezone}`, {
        headers: { Authorization: `Bearer ${token}` },
    });
    return response.data;
};
